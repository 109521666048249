<template>
  <div class="contact">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__contactUs") }}</h2>
      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="device-report-select">
          <select v-model="searchType" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t("__all") }}</option>
            <option value="1">
              {{ $t("__processed") }}
            </option>
            <option value="0">
              {{ $t("__pending") }}
            </option>
          </select>
        </div>
        <div class="ms-2 device-report-select">
          <select v-model="searchAgency" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t("__allAgency")}}</option>
            <option v-for="agency in agencys" :key="'agency'+agency.id" :value="agency.id">{{ agency.name }}</option>
          </select>
        </div>
        <div class="ms-md-2 mt-2 mt-md-0 device-report-select">
          <select v-model="sortAgency" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t("__sort") }}</option>
            <option :value="['created_at', 'asc']">{{ $t("__sortNotifyOldToNew")}}</option>
            <option :value="['created_at', 'desc']">{{ $t("__sortNotifyNewToOld") }}</option>
            <option :value="['updated_at', 'asc']">{{ $t("__sortUpdateOldToNew") }}</option>
            <option :value="['updated_at', 'desc']">{{ $t("__sortUpdateNewToOld") }}</option>
          </select>
        </div>
      </div>
      <div class="card">
        <div class="card-body" v-if="items">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            :fields="fields"
            :empty-text="$t('__notData')"
            :items="items.data"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(agency)="data">
              {{
                data.item.agency.name +
                " / " +
                data.item.agency.codename
              }}
            </template>
            <template v-slot:cell(user)="data">
              {{
                data.item.user.name +
                " / " +
                data.item.user.username
              }}
            </template>
            <template v-slot:cell(status)="data">
              <span :class="data.item.status ? 'text-primary' : 'text-danger'">
                {{ data.item.status ? $t('__processed') :  $t('__pending') }}</span
              >
              {{ " / " + getTypeName(data.item.type) }}
            </template>
            <template v-slot:cell(created_at)="data">
              {{ new Date(data.item.created_at).format("yyyy-MM-dd hh:mm") }}
            </template>
            <template v-slot:cell(updated_at)="data">
              {{ new Date(data.item.updated_at).format("yyyy-MM-dd hh:mm") }}
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  @click="showModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="items">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: items.current_page == 1
              ? 1
              : items.per_page * (items.current_page - 1) + 1,
            entNub: currentPage == 1
              ? items.total > items.per_page
                ? items.per_page
                : items.total
              : items.per_page * (items.current_page - 1) + items.per_page >
                items.total
              ? items.total
              : items.per_page * (items.current_page - 1) + items.per_page,
            sum: items.total
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="items.total > items.per_page"
            v-model="items.current_page"
            :total-rows="items.total"
            :per-page="items.per_page"
            align="right"
            first-number
            last-number
            @click.native="pagination(items.current_page)"
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ $t("__serialNumber") + "：" + modalData.id + " " + $t("__processingReturns") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-medium">{{ $t("__questionType") }}</label>
                <p class="text-secondary">{{ getTypeName(modalData.type) }}</p>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{ $t("__description") }}</label>
                <p class="text-secondary">{{ modalData.description }}</p>
              </div>
              <div class="mb-3">
                <label for="status" class="form-label fw-medium required">{{
                  $t("__processingStatus")
                }}</label>
                <select
                  v-model="modalData.status"
                  id="status"
                  class="form-select"
                  :class="{
                    'is-invalid': validate && validate.msg.status
                  }"
                  required
                >
                  <option :value="1">
                    {{ $t("__processed") }}
                  </option>
                  <option :value="0">
                    {{ $t("__pending") }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="validate && validate.msg.status">
                  <div v-for="(msg, index) in validate.msg.status" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="records" class="form-label fw-medium required">{{
                  $t("__processingContent")
                }}</label>
                <textarea
                  v-model="modalData.records"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.records
                  }"
                  id="records"
                  rows="3"
                  required
                ></textarea>
                <div class="invalid-feedback" v-if="validate && validate.msg.records">
                  <div v-for="(msg, index) in validate.msg.records" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { contact } from '@/http/api/contact.js'
import { agency } from '@/http/api/agency.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Contact',
  data () {
    return {
      agencys: null,
      items: null,
      searchType: null,
      searchAgency: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      isEdit: false,
      editModalId: null,
      filter: {
        agency_id: null,
        status: null,
        sort_by: null,
        sort_type: null
      },
      modalData: {
        id: null,
        type: null,
        description: null,
        status: null,
        records: null
      },
      validate: null,
      sortAgency: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    fields () {
      return [
        {
          key: 'id',
          label: i18n.t('__serialNumber'),
          class: 'align-middle'
        },
        {
          key: 'agency',
          label: i18n.t('__agency') + ' / ' + i18n.t('__codeName'),
          class: 'align-middle'
        },
        {
          key: 'user',
          label: i18n.t('__name') + ' / ' + i18n.t('__account'),
          class: 'align-middle text-center'
        },
        {
          key: 'status',
          label: i18n.t('__status') + ' / ' + i18n.t('__questionType'),
          class: 'align-middle text-center'
        },
        {
          key: 'created_at',
          label: i18n.t('__notificationTime'),
          class: 'align-middle text-center'
        },
        {
          key: 'updated_at',
          label: i18n.t('__updated'),
          class: 'align-middle text-center'
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle'
        }
      ]
    },
    permissions () {
      return this.userInfo.permissions
    },
    isSystemManage () {
      let systemManage = false
      this.permissions.forEach((item) => {
        if (item.name === 'system-manage') {
          systemManage = true
        }
      })
      return systemManage
    }
  },
  watch: {
    sortAgency () {
      const vm = this
      vm.filter.sort_by = vm.sortAgency ? vm.sortAgency[0] : null
      vm.filter.sort_type = vm.sortAgency ? vm.sortAgency[1] : null
      vm.getcontact(1)
    },
    searchAgency () {
      const vm = this
      vm.getcontact(1)
    },
    searchType () {
      const vm = this
      vm.getcontact(1)
    },
    items (data) {
      const vm = this
      if (data) {
        vm.initBootstrapTooltip()
      }
    },
    modalData: {
      handler: function () {
        this.validate = null
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getAgency () {
      const vm = this
      agency.get('', vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.agencys = res.data.data
        }
      })
    },
    getTypeName (id) {
      switch (id) {
        case 1:
          return i18n.t('__gettingOutOfBed')
        case 2:
          return i18n.t('__sleep')
        case 3:
          return i18n.t('__breathe')
        case 4:
          return i18n.t('__restlessness')
        case 5:
          return i18n.t('__longInBed')
        case 6:
          return i18n.t('__network')
        case 7:
          return i18n.t('__other')
      }
    },
    getcontact (page) {
      const vm = this
      var config = Object.assign({}, this.token)
      vm.filter.agency_id = vm.searchAgency
      vm.filter.status = vm.searchType
      config.params = this.filter
      config.params.page = page
      vm.Loading()
      contact
        .get('', config)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.items = res.data.data
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    clearSearch () {
      this.searchAgency = null
      this.getcontact(1)
    },
    pagination (currentPage) {
      this.getcontact(currentPage)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    showModal (id) {
      const vm = this
      vm.editModalId = id
      if (id) {
        vm.isEdit = true
        vm.items.data.forEach((item) => {
          if (item.id === id) {
            vm.modalData.id = id
            vm.modalData.status = item.status
            vm.modalData.records = item.records
            vm.modalData.type = item.type
            vm.modalData.description = item.description
          }
        })
      } else {
        vm.isEdit = false
        vm.modalData.id = null
        vm.modalData.status = null
        vm.modalData.records = null
        vm.modalData.type = null
        vm.modalData.description = null
      }
      vm.bootstrap_modal = new Modal(vm.$refs.editModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    submitForm () {
      const vm = this
      vm.Loading()
      contact
        .update(
          vm.editModalId,
          {
            status: vm.modalData.status,
            records: vm.modalData.records
          },
          vm.token
        )
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getcontact(1)
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.validate = res.data.validate
          }
          vm.Loaded()
          vm.Alerted()
        })
    }
  },
  created () {
    this.getcontact(1)
    this.getAgency()
  },
  mounted () {}
}
</script>
